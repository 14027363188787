import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  Grid,
  Typography,
  makeStyles,
  Container,
  Paper,
  Button,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { IoIosClose } from 'react-icons/io';
import Accommodation from '../Request-Application/Accommodation/Accommodation';
import { getAllRoomDetails } from '../../../../utils/ApiService';

const useStyles = makeStyles(theme => ({
  header: {
    color: '#222222',
    fontSize: '14px',
    fontFamily: 'Lato',
    fontWeight: 700,
    lineHeight: '22px',
    wordWrap: 'break-word',
    textAlign: 'left',
  },
  box: {
    boxShadow: 'none',
    borderRadius: 8,
    padding: '20px',
    minHeight: '72vh',
  },
  closeICO: {
    float: 'right',
    fontSize: 24,
    cursor: 'pointer',
  },
  description: {
    marginTop: theme.spacing(5),
    textAlign: 'left',
    lineHeight: '22.5px',
    fontSize: '12px',
  },
  boxContainer: {
    display: 'flex',
    gap: 20,
    marginTop: theme.spacing(2),
  },
  switchText: {
    fontSize: '20px',
    fontWeight: 500,
    marginRight: '30px',
    lineHeight: '22.5px',
  },
  switchCase: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  dialog: {
    '& .MuiDialog-paperFullWidth': {
      padding: '16px',
      minHeight: '40vh',
    },
    '& .MuiDialogContent-root': {
      flexDirection: 'column',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    },
    '& .MuiButton-containedPrimary': {
      textTransform: 'capitalize !important',
    },
    '& .MuiButton-outlinedPrimary': {
      textTransform: 'capitalize !important',
      border: 'none',
    },
    '& .MuiAccordionDetails-root': {
      flexDirection: 'column',
    },
  },
  dialogHeader: {
    color: '#222',
    fontSize: '18px',
    fontWeight: '600',
  },
  dialogBody: {
    marginTop: 40,
  },
  statusImage: {
    width: '60px',
  },
  closeButtonDiv: {
    marginTop: '10px',
    minHeight: '20vh',
    width: '127px',
    display: 'flex',
    justifyContent: 'end',
    flexDirection: 'column',
    float: 'right',
  },
}));


const ResidenceAllocationClosed = props => {
  const { handleClose } = props;
  const CONTACT_ID = JSON.parse(localStorage.getItem('userDetails')).ContactId;
  const classes = useStyles();
  const { t } = useTranslation();
  const [data, setData] = useState([]);

  useEffect(() => {
    getAllRoomDetails(CONTACT_ID)
      .then(res => {
        const data = [];
        res?.data?.forEach((items, index) => {
          const lists = items?.Description__c?.split(';') || [];
          const hasKeyword = keyword =>
            lists.some(text =>
              text.toLowerCase().includes(keyword.toLowerCase())
            );
          const icons = [];
          if (hasKeyword('Non-air conditioned')) {
            icons.push('non-ac');
          }
          if (hasKeyword('Air-conditioned')) {
            icons.push('ac');
          }
          if (hasKeyword('twin-sharing')) {
            icons.push('twin-sharing');
          }
          if (hasKeyword('Flat Style')) {
            icons.push('flat-style');
          }
          if (hasKeyword('Standalone Room accommodation')) {
            icons.push('single-room');
          }
          data.push({
            ...items,
            id: index,
            icons: icons,
            Description__c: lists,
            preference: null,
          });
        });
        setData(data);
      })
      .catch(err => {
        console.error(err);
      });
  }, []);

  return (
    <>
      <Grid container xs={12} style={{ minHeight: '75vh' }}>
        <Grid item component="main" xs={12} style={{ marginTop: '20px' }}>
          <Paper className={classes.box}>
            <Grid item xs={12}>
              <Typography variant="p" className={classes.header}>
                {t(`headings:Provisional_Allotment`)}
              </Typography>
              <IoIosClose className={classes.closeICO} onClick={handleClose} />
            </Grid>
            <Grid item xs={12} style={{ minHeight: '62vh' }}>
              <Typography variant="p" className={classes.description}>
                {t(`content:Room_Alloted`)}
              </Typography>
              <Grid variant="div" className={classes.boxContainer}>
                {data &&
                  data.map((items, index) => {
                    return (
                      <Grid item lg={3} md={4} sm={6} xs={12}>
                        <Accommodation item={items} key={index} />
                      </Grid>
                    );
                  })}
              </Grid>
              <Grid container xs={12} className={classes.closeButtonDiv}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    textTransform: 'capitalize',
                    marginRight: '10px',
                    height: '35px',
                  }}
                  onClick={handleClose}
                >
                  {t(`Buttons:Close_Details`)}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default ResidenceAllocationClosed;
