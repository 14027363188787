import { Redirect } from "react-router-dom";
import { KEY_USER_DETAILS } from "../constants";

export const PublicRoute = ({ children }) => {
  const userDetails = JSON.parse(localStorage.getItem(KEY_USER_DETAILS));
  if (userDetails) {
    return <Redirect to={"/residence-allocation"} />;
  }
  return children;
};

export const PrivateRoute = ({ children }) => {
  const userDetails = JSON.parse(localStorage.getItem(KEY_USER_DETAILS));
  if (!userDetails) {
    return <Redirect to={"/"} />;
  }
  return children;
};
