import { SnackbarProvider } from "notistack";
import { I18nextProvider } from "react-i18next";
import "./App.css";
import MainLayout from "./Container/MainLayout";
import { AppContextProvider } from "./utils/contextProvider/AppContext";
import i18n from "./utils/i18next/i18n";
import ThemeProvider from "./utils/themeProvider";
import { ErrorBoundary } from "react-error-boundary";
import {ErrorFallback} from "./utils/ErrorFallBack"

function App() {
  return (
    <AppContextProvider>
      <SnackbarProvider>
        <I18nextProvider i18n={i18n}>
          <ThemeProvider>
            <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => {
              window.location.reload()
            }}>
            <MainLayout />
            </ErrorBoundary>
          </ThemeProvider>
        </I18nextProvider>
      </SnackbarProvider>
    </AppContextProvider>
  );
}

export default App;
