import axios from "axios";
import { KEY_ACCESS_TOKEN, KEY_CONFIG_URL, KEY_COURSE, KEY_LOGIN_ACCESS_TOKEN } from "./constants";

let appConfig;
export let axiosInstance;
const configUrl = KEY_CONFIG_URL;

export const getAxiosInstance = async (data) => {
  appConfig = data[0]?.config;
  if (axiosInstance) {
    return axiosInstance;
  }
  axiosInstance = axios.create({
    baseURL: `https://flame-prod-api-az.ken42.com/pfs-integration/api`,
    // timeout: 15000,
  });

  axiosInstance.interceptors.request.use((req) => {
    const token = localStorage.getItem(KEY_ACCESS_TOKEN);
    req.headers["access-token"] = localStorage.getItem(KEY_LOGIN_ACCESS_TOKEN);
    req.headers.Authorization = `Bearer ${token}`;
    return req;
  });

  //Response interceptor for API calls
  axiosInstance.interceptors.response.use(
    (resp) => {
      return resp;
    },
    async function (error) {
      console.log("ApiService: Interceptor: ", error);
      if (!error.response?.config && error.response?.status !== 401) {
        return error;
      }
      var config = {
        method: "get",
        baseURL: `${appConfig.tokenUrl}`,
      };
      return axios(config).then(function (response) {
        localStorage.setItem(KEY_ACCESS_TOKEN, response.data.access_token);
        error.response.config.headers["Authorization"] = `Bearer ${response.data.access_token}`;
        return axios(error.response.config);
      });
    }
  );
};

export const getConfig = async () => {
  const path = "flame-portal-uat.ken42.com";
  const res = await axios.get(`${configUrl}/${path}`);
  return res && res.data ? res.data : null;
};

export const getCurrentStage = async (contact) => {
  const path = `/sf/residence/getCurrentStage/${contact}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const getApplications = async (contact) => {
  const path = `/sf/residence/getRoomDetails/${contact}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const getResidenceHistory = async (contact) => {
  const path = `/sf/residence/getResidentHistory/${contact}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const getProvisionalAllotmentDetails = async (contact) => {
  const path = `/sf/residence/getProvisionalAllotmentDetails/${contact}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const postRoomRequest = async (payload) => {
  const path = `/sf/residence/postRoomRequest`;
  const res = await axiosInstance.post(`${path}`, payload);
  return res && res.data ? res.data : null;
};

export const getAllRoomDetails = async (contact) => {
  const path = `/sf/residence/getAllRoomDetails/${contact}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const getEligibilityDetails = async (contact) => {
  const path = `/sf/residence/getEligibilityDetails/${contact}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};


export const hdfcPaymentIntegartion = async (data) => {
  const path = "/hdfc/payments/initiate";
  const res = axiosInstance.post(path, data);
  return res;
};

export const getFeeReceipt = async (transactionId) => {
  const path = `/salesforce?endpoint=${encodeURIComponent(`/services/apexrest/portalfee/retrieve/receipt?transactionid=aBC1m000000Cb1AGAS`)}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};


export const getCurrentBatch = async (contactId) => {
  const path = `/sf/get_batch_info?contactId=${contactId}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};


export const getDocumentUrl = async (documentId) => {
  const path = `/sf/get/residence/contentVersion/${documentId}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};